import { Badge, Text } from '@libs/components'
import { useTheme } from '@libs/theme'
import { DateFormat, SecureStore } from '@libs/utils'
import { isWeb } from '@libs/utils/src/screenLayout'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import {
  Image,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native'
import { formatIntakeDate } from '@libs/utils/src/dateformat'
import { MyApplicationMobile } from './index.mobile'

export const MyApplication = ({ data, style, disabled }) => {
  const isDesktop = isWeb(useWindowDimensions().width)
  const [brandLogoUrl, setBrandLogoUrl] = useState('')
  const { colors } = useTheme()
  const navigation = useNavigation()

  const handleNavigation = () => {
    navigation.navigate('view-application-details', {
      id: data.Id,
      isActive:
        data?.ActionRequiredCount > 0 ? 'ACTION_REQUIRED' : 'INFORMATION',
      isDashboard: true,
    })
  }

  const formattedStatus = (status) => {
    if (status?.split(' ').length === 2) {
      return status?.split(' ')[1]
    } else if (
      status?.split(' ').length === 3 &&
      status?.split(' ')[0] === 'Visa'
    ) {
      return 'Visa'
    } else {
      return status
    }
  }

  const isFocused = useIsFocused()

  const badgeColor = {
    Offer: {
      color: '#009951',
      backgroundColor: '#CFF7D3',
    },
    Accepted: {
      color: '#009951',
      backgroundColor: '#CFF7D3',
    },
    Enrolment: {
      color: '#009951',
      backgroundColor: '#CFF7D3',
    },
    Admission: {
      color: '#D4691B',
      backgroundColor: '#FFF4E0',
    },
    Draft: {
      backgroundColor: '#FFF4E0',
      color: '#D4691B',
    },
    Visa: {
      backgroundColor: '#FFF4E0',
      color: '#D4691B',
    },
    Withdrawn: {
      backgroundColor: '#FDD3D0',
      color: '#C00F0C',
    },
  }

  const duration = data?.OpportunityLineItems?.records[0]?.Product2?.Duration__c
  const opportunitiesDetails = [
    {
      label: t('APPLICATION_DETAIL.INTAKE'),
      value: data.IntakeDate ? formatIntakeDate(data.IntakeDate) : '-',
      separate: false,
    },
    {
      value: duration || '-',
      label: t('APPLICATION_DETAIL.DURATION'),
      separate: false,
    },
    {
      label: t('APPLICATION_DETAIL.LOCATION'),
      value:
        data?.Location__c ??
        data?.OpportunityLineItems?.records?.[0]?.Location__c ??
        '-',
      separate: false,
    },
  ]

  useEffect(() => {
    if (!isFocused) return
    ;(async () => {
      const configData = await SecureStore.getItemAsync('config')
      const config = JSON.parse(configData)?.config
      setBrandLogoUrl(config?.brandLogoUrl)
    })()
  }, [isFocused])

  const programName = data?.ProgrammeName__c
    ? `${data?.ProgrammeName__c}${
        data?.Delivery_Mode__c ? `, ${data?.Delivery_Mode__c}` : ''
      }`
    : ''

  if (isDesktop) {
    return (
      <TouchableOpacity
        style={[styles.mainContainer, style]}
        onPress={() => handleNavigation()}
        disabled={disabled}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 8,
            flexWrap: !isDesktop ? 'wrap' : 'nowrap',
          }}
        >
          <Image
            source={`${brandLogoUrl}/${data.BusinessUnitFilter__c?.replaceAll(
              ' ',
              '_',
            )}.png`}
            style={{
              height: 55,
              width: 126,
            }}
            alt={data?.brand}
            resizeMode="contain"
          />
          {isDesktop ? (
            <View
              style={{
                justifyContent: 'flex-start',
                flex: 1,
                alignSelf: 'baseline',
              }}
            >
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: 16,
                  color: colors.cardTitleColor,
                  paddingRight: 20,
                }}
              >
                {programName ? programName : data?.Name?.split('_')[0]}
              </Text>

              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                  gap: 4,
                  flexWrap: 'wrap',
                }}
              >
                {opportunitiesDetails?.slice(0, 3).map((item, index) => (
                  <View
                    key={index}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Text variant="display4" color={colors.textSecondary}>
                      <Text variant="display4" color={colors.onNeutral}>
                        {item.label}:{' '}
                      </Text>
                      {item.value}
                    </Text>
                    {opportunitiesDetails.length - 1 !== index && (
                      <View style={styles.divider(colors)} />
                    )}
                  </View>
                ))}
              </View>
            </View>
          ) : null}

          <View
            style={{
              justifyContent: 'flex-start',
              alignSelf: isDesktop ? 'baseline' : 'center',
              flexGrow: 0,
            }}
          >
            <Badge
              style={[
                badgeColor[data?.ApplicationStatus?.status.split(' ')[0]] ||
                  badgeColor[data?.ApplicationStatus?.status.split('/')[0]],
              ]}
              textVariant="display4"
            >
              Status : {formattedStatus(data?.ApplicationStatus?.status)}
            </Badge>
          </View>
        </View>
      </TouchableOpacity>
    )
  }
  return <MyApplicationMobile data={data} style={style} disabled={disabled} />
}

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 10,
    backgroundColor: '#fff',
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  data: () => ({
    flexDirection: window.innerWidth >= 376 ? 'row' : 'column',
    flexWrap: 'wrap',
    gap: window.innerWidth <= 376 ? 6 : 0,
  }),
  divider: (colors) => ({
    backgroundColor: colors.onNeutral,
    width: 1,
    height: '100%',
    marginHorizontal: 15,
  }),
  buttonStyleOutlined: (colors, isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 40 : 20,
    paddingVertical: 10,
    borderColor: colors.primaryContainer,
  }),
  buttonStyleFilled: (isDesktop) => ({
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: isDesktop ? 40 : 20,
    paddingVertical: 10,
  }),
})
